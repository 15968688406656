<template>
  <Layout :tituloPagina="'Network | Planes de servicio | ' +(modo == 'nuevo' ? 'Nuevo' : 'Edición')">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          {{modo=='nuevo'?'Nuevo': 'Edición de'}} plan de servicio
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="modo == 'nuevo' ? guardar() : actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="cerrar()"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <label>Nombre</label>
            <input
              class="form-control"
              ref="nombre"
              v-model="plan.nombre"
              placeholder="Nombre del servicio"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <label>Descripción</label>
            <textarea
              class="form-control"
              ref="descripcion"
              placeholder="Descripción del servicio"
              v-model="plan.descripcion"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label>Estado</label>
            <select
              ref="activo"
              class="form-select"
              v-model="plan.activo"
            >
              <option :value="1" selected>Activo</option>
              <option :value="0">Inactivo</option>
            </select>
          </div>
          <div class="col-md-3">
            <label>Categoría</label> <br />
            <span class="form-control">
              {{ categoria.nombre }}
            </span>
          </div>
          <div class="col-md-3">
            <label>Perfil de velocidad</label>
            <select
              ref="idPerfilVelocidadCpes"
              class="form-select"
              v-model="plan.id_perfil_velocidad_cpes"
            >
              <option :value="null">Seleccionar</option>
              <option
                v-for="perfil in perfiles"
                :value="perfil.id"
                :key="perfil.id"
              >
                {{ perfil.nombre }}
              </option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label>Precio</label>
            <div class="input-group">
              <div class="input-group-text">
                <span :title="moneda == null? '': moneda.nombre">
                  {{moneda == null? '$': moneda.simbolo}}
                </span>
              </div>
              <input
                type="number"
                ref="precio"
                class="form-control text-right"
                v-model="plan.precio"
                placeholder="0.00"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary" @click="atras()">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button
          class="btn btn-success" 
          @click="modo=='nuevo'? guardar(): actualizar()"
          :disabled="bandera_spinner"
        >
          <i 
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          {{modo=='nuevo'?'Guardar': 'Actualizar'}}
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import CategoriaProductosSrv from '@/services/CategoriaProductosSrv.js'
import PlanServicioSrv from '@/services/PlanServicioSrv.js'
import PerfilVelocidadCpesSrv from '@/services/PerfilVelocidadCpesSrv.js'
import SistemaSrv from '@/services/SistemaSrv.js'
export default {
  name: 'EdicionPlanServicio',
  components: {
    Layout
  },
  data() {
    return {
      modo: 'nuevo',
      categoria: {},
      perfiles: [],
      planInicial: {
        activo: 1,
        id_perfil_velocidad_cpes: null,
        nombre: '',
        descripcion: '',
        precio: 0,
        servicio: 1
      }, // Servirá de respaldo para limpiar el contenido del formulario
      plan: {
        activo: 1,
        id_perfil_velocidad_cpes: null,
        nombre: '',
        descripcion: '',
        precio: 0,
        servicio: 1
      },
      moneda: null,
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    // Registro de una copia del plan inicial
    this.planInicial = Object.assign({}, this.plan)

    // Carga del modo
    if(self.$route.path.indexOf('nuevo') == -1) self.modo = 'edicion'

    // Cargas iniciales
    self.cargarCategoria(function() {
      self.cargarPerfiles(function() {
        if(self.modo=='edicion') self.cargarPlan()
      })
    })

    self.cargarMoneda()
  },

  methods: {
    actualizar: function() {
      var self = this
      self.bandera_spinner = true

      if(this.plan.nombre == null || this.plan.nombre == ''){
        self.$refs.nombre.select()
        iu.msg.warning('Es nuecesario un nombre para el plan de servicio')
        self.bandera_spinner = false
        return
      }

      // Verificación de estado "activo"
      if (this.plan.activo == null) {
        self.$refs.activo.select()
        iu.msg.warning('Es necesario seleccionar un estado')
        self.bandera_spinner = false
        return
      }

      // Verificación del perfil de velocidad asignado
      if (this.plan.id_perfil_velocidad_cpes == null) {
        self.$refs.idPerfilVelocidadCpes.focus()
        iu.msg.warning('Es necesario seleccionar un perfil de velocidad')
        self.bandera_spinner = false
        return
      }

      var plan = Object.assign({}, this.plan)
      
      PlanServicioSrv.actualizar(plan).then(response => {
        iu.msg.success('Se actualizó correctamente')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarCategoria: function(callback) {
      var self = this
      
      CategoriaProductosSrv.categoriaJSON(2).then(response => {
        self.categoria = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la categoria'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMoneda() {
      let self = this

      SistemaSrv.monedaJSON().then(response => {
        self.moneda = response.data
      });
    },

    cargarPerfiles: function(callback) {
      var self = this

      PerfilVelocidadCpesSrv.perfilesJSON().then(response => {
        self.perfiles = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los perfiles de velocidad'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPlan: function() {
      var self = this

      PlanServicioSrv.planJSON(this.$route.params.id).then(response => {
        self.plan = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el plan de servicio'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },


    guardar: function() {
      var self = this

      self.bandera_spinner = true

      if(this.plan.nombre == null || this.plan.nombre == ''){
        self.$refs.nombre.select()
        iu.msg.warning('Es nuecesario un nombre para el plan de servicio')
        self.bandera_spinner = false
        return
      }

      // Verificación de estado "activo"
      if (this.plan.activo == null) {
        self.$refs.activo.select()
        iu.msg.warning('Es necesario seleccionar un estado')
        self.bandera_spinner = false
        return
      }

      // Verificación del perfil de velocidad asignado
      if (this.plan.id_perfil_velocidad_cpes == null) {
        self.$refs.idPerfilVelocidadCpes.focus()
        iu.msg.warning('Es necesario seleccionar un perfil de velocidad')
        self.bandera_spinner = false
        return
      }

      var plan = Object.assign({}, this.plan)

      PlanServicioSrv.guardar(plan).then(response => {
        iu.msg.success('Se guardó correctamente')
        // Limpieza del formulario
        self.plan = Object.assign({}, this.planInicial)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo guardar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    }
  },
}
</script>

<style scoped>

</style>